<template>
  <div>
    <error-screen title="Unable to locate parcel" subtitle="Please ensure tracking is correct or search via branch search" v-if="error"/>
    <div class="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8" v-if="parcel">
      <div class="p-6 mt-8 bg-white rounded-md shadow">
        <div>
          <div class="flex">
            <div class="w-14">
              <div class="relative">
                <img class="w-10 h-10 rounded-md" :src="getIconUrl($route.params.carrierId)" alt="Carrier Logo">
              </div>
            </div>
            <div class="w-11/12">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Parcel Information</h3>
              <p class="max-w-2xl text-xs text-gray-500">Last updated at {{ parcel.current.createdAt | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
            </div>
            <div class="float-right w-1/12 mr-6">
                <button @click="$router.push('/')" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-gray-300 rounded-md shadow-sm bg-pol-grey hover:bg-gray-900">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
                  </svg>
                  Back
                </button>
            </div>
          </div>
        </div>
        <div class="mt-5 border-t border-gray-200">
          <component v-bind:is="getTemplate(parcel.current.carrierId)" v-on:notify="parcelUpdated()" :version="parcel.current" />
        </div>
      </div>

    <!-- branch changes strat -->
     <div class="p-6 mt-6 bg-white rounded-md shadow" v-if="branchDetails.branchId">
        <div class="mb-4">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Branch Information</h3>
        </div>
        <div class="mt-5 border-t border-gray-200">
          <BranchInfo :branchInfo="branchDetails"/>
        </div>
      </div>
      <!-- branch changes end -->

      <div class="p-6 mt-6 bg-white rounded-md shadow" v-if="parcel.versions">
        <div class="mb-4">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Versions</h3>
          <p class="max-w-2xl text-xs text-gray-500">Previous updates to this record by a branch/carrier</p>
        </div>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div class="bg-white border border-gray-200 rounded-md">
          <ul>
              <li v-for="(version, index) in parcel.versions" :key="version.sk" class="relative">
                <button type="button" :class="{ 'rounded-t-md': index == 0 }" class="w-full px-6 py-3 text-left bg-gray-50" @click="selectedVersion !== index ? selectedVersion = index : selectedVersion = null">
                    <div class="flex items-center justify-between">
                      <span class="inline-flex px-2 text-xs leading-5 bg-yellow-100 rounded-full">
                        <pre>{{ version.sk }}</pre>
                      </span>
                      <span class="text-xs">
                        {{ version.createdAt | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}
                      </span>
                      <span class="text-xs lg:w-20">
                        <pre>{{ version.status }}</pre>
                      </span>
                      <svg v-if="selectedVersion !== index" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                </button>
                <div v-if="selectedVersion == index">
                    <div class="relative px-6">
                      <component v-bind:is="getTemplate(version.carrierId)" :version="version" />
                    </div>
                </div>
              </li>
          </ul>
        </div>
        </dd>
      </div>
    </div>
  </div>
</template>
<script>

import { Auth } from 'aws-amplify'
import DefaultParcel from '@/components/Parcels/Default'
import BranchInfo from '@/components/Parcels/BranchInfo'
import RoyalMailParcel from '@/components/Parcels/RoyalMail'
import ErrorScreen from '../components/ErrorScreen'

export default {
  name: 'Tracking',
  metaInfo: {
    title: 'Tracking Parcel'
  },
  components: {
    DefaultParcel,
    BranchInfo,
    RoyalMailParcel,
    ErrorScreen
  },
  data () {
    return {
      error: false,
      parcel: null,
      branchDetails: null,
      selectedVersion: null
    }
  },
  async created () {
    this.loadParcelData()
  },
  methods: {
    getTemplate (carrierId) {
      switch (carrierId) {
        case 'RM':
          return RoyalMailParcel
        default:
          return DefaultParcel
      }
    },
    async loadParcelData () {
      const loader = this.$loading.show()
      try {
        const user = await Auth.currentAuthenticatedUser()
        this.$http.defaults.headers.common.Authorization = user.signInUserSession.idToken.jwtToken
        const apiPath = `${process.env.VUE_APP_API_BASE}/parcel/${this.$route.params.carrierId}/${this.$route.params.trackingId}`
        const result = await this.$http.get(apiPath)
        const branchId = result.data.current.branchId
        const branchApiPath = `${process.env.VUE_APP_API_BASE}/branchDetails/${branchId}`
        const branchDetails = await this.$http.get(branchApiPath)
        this.branchDetails = branchDetails.data
        this.branchDetails.carrierId = this.$route.params.carrierId
        this.parcel = result.data
      } catch (error) {
        this.error = true
      }
      // hide loader
      loader.hide()
    },
    parcelUpdated () {
      this.loadParcelData()
    }
  }
}
</script>
<style scoped>
  .parcel-row {
    @apply py-4 sm:grid sm:py-3 sm:grid-cols-3 sm:gap-4;
  }
</style>
