<template>
  <dl class="grid grid-cols-2 divide-y divide-gray-200">
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Tracking ID</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre>{{ version.trackingId }}</pre>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Branch ID</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre>{{ version.branchId }}</pre>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Clerk ID</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre v-if="version.clerkId">{{ version.clerkId }}</pre>
          <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Counter ID</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre v-if="version.counterId">{{ version.counterId }}</pre>
          <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Service Name</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre v-if="version.serviceName">{{ version.serviceName }}</pre>
          <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Address Line 1</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre v-if="version.addressLine1">{{ version.addressLine1 }}</pre>
          <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Postcode</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre v-if="version.postcode">{{ version.postcode }}</pre>
          <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Country</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre v-if="version.country">{{ version.country }}</pre>
          <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">After last despatch</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <span v-if="version.afterLastDespatch" class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Yes</span>
          <span v-else class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">No</span>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Status</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <span class="inline-flex px-2 text-xs font-semibold leading-5 text-black bg-gray-100 rounded-full">
            {{ version.status }}
          </span>
        </span>
      </dd>
    </div>
  </dl>
</template>

<script>
export default {
  name: 'RoyalMail',
  props: {
    version: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      dateDisplayFormat: 'dddd, MMMM Do YYYY, h:mm:ss a'
    }
  }
}
</script>

<style scoped>
  .parcel-row {
    @apply py-4 sm:grid sm:py-3 sm:grid-cols-3 sm:gap-4;
  }
</style>
