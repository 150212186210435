<template>
    <dl class="grid grid-cols-2 divide-y divide-gray-200">
            <div class="branch-row">
              <dt class="text-sm font-medium text-pol-grey">Branch ID</dt>
              <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow">
                  <pre>{{branchInfo.branchId}}</pre>
                </span>
              </dd>
            </div>
            <div class="branch-row">
              <dt class="text-sm font-medium text-pol-grey">Branch Name</dt>
              <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow">
                  <pre>{{branchInfo.name}}</pre>
                </span>
              </dd>
            </div>
            <div class="branch-row">
              <dt class="text-sm font-medium text-pol-grey">Branch Email</dt>
              <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow">
                  <pre>{{branchInfo.email}}</pre>
                </span>
              </dd>
            </div>
            <div class="branch-row">
              <dt class="text-sm font-medium text-pol-grey">Branch Phone Number</dt>
              <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow">
                  <pre>{{branchInfo.phoneNumber}}</pre>
                </span>
              </dd>
            </div>
            <div class="branch-row">
              <dt class="text-sm font-medium text-pol-grey">Branch Post Code</dt>
              <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow">
                  <pre>{{branchInfo.postCode}}</pre>
                </span>
              </dd>
            </div>
            <div class="branch-row">
              <dt class="text-sm font-medium text-pol-grey">Branch Area Manager</dt>
              <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow">
                  <pre>{{branchInfo.areaManager}}</pre>
                </span>
              </dd>
            </div>
            <div class="branch-row">
              <dt class="text-sm font-medium text-pol-grey">Branch Type</dt>
              <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow">
                  <pre>{{branchInfo.branchType}}</pre>
                </span>
              </dd>
            </div>
            <div v-if="branchInfo.carrierId == 'evri'" class="branch-row">
              <dt class="text-sm font-medium text-pol-grey">Evri FadCode</dt>
              <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="flex-grow">
                  <pre>{{branchInfo.evriFadcode}}</pre>
                </span>
              </dd>
            </div>
          </dl>
</template>
<script>

export default {
  name: 'BranchInfo',
  props: {
    branchInfo: {
      required: true,
      type: Object
    }
  }
}
</script>
<style scoped>
  .branch-row {
    @apply py-4 sm:grid sm:py-3 sm:grid-cols-3 sm:gap-4;
  }
</style>
